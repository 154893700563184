import React from 'react';
import styled from 'styled-components';

interface H2Props {
  children: React.ReactNode;
}

const H2 = ({ children }: H2Props) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled('h2')`
  font-family: Tiempos Headline;
  color: ${({ theme }) => theme.login.title};
  font-size: 32px;
  line-height: 42px;
  font-weight: 100;
  margin-top: 50px;
`;

export default H2;
