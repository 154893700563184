import React from 'react';
import { Button, H2, Text } from 'components/Login';

import { redirectToPaperwork } from '../api/login';

const Verified = () => {
  return (
    <React.Fragment>
      <H2>Verified!</H2>
      <Text>Your account has been verified.</Text>
      <Button onClick={() => redirectToPaperwork()}>
        Continue to Paperwork
      </Button>
    </React.Fragment>
  );
};

export default Verified;
