import { USER_POOL_ID, COGNITO_USER_CUSTOMER_CLIENT_ID } from '/config';
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';
import initApollo from '/apollo-wrapper/init-apollo';
import gql from 'graphql-tag';

import { MenuSteps } from '../';
const client = initApollo();

export const redirectToPaperwork = () => {
  location.href = '/paperwork-new';
};

export const login = async (
  values: { email: string; password: string },
  setCurrentStep: (index: number) => void,
  userContext: any,
  setErrors: any
) => {
  const { email = '', password = '' } = values;
  let poolData = {
    UserPoolId: USER_POOL_ID,
    ClientId: COGNITO_USER_CUSTOMER_CLIENT_ID,
  };

  let userPool = new CognitoUserPool(poolData);

  let userData = {
    Username: email,
    Pool: userPool,
  };

  let authenticationData = {
    Username: email,
    Password: password,
  };

  let authenticationDetails = new AuthenticationDetails(authenticationData);

  let cognitoUser = new CognitoUser(userData);
  const { setCognitoUser } = userContext;
  return await cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: async result => {
      let accessToken = result.getAccessToken().getJwtToken();
      // todo: user the paperwork url
      const mutation = gql`
        mutation Login($cognitoToken: String!) {
          login(cognitoToken: $cognitoToken) {
            ok
          }
        }
      `;
      const response = await client.mutate({
        mutation,
        variables: { cognitoToken: accessToken },
      });
      if (response.data.login.ok) {
        redirectToPaperwork();
      }
    },
    onFailure: e => {
      // todo: throw a raven depending on the type of error
      if (e.code === 'UserNotConfirmedException') {
        setCognitoUser(cognitoUser);
        setCurrentStep(MenuSteps.confirmRegistration);
      }
      setErrors(e);
    },
    newPasswordRequired: async userAttributes => {
      setCognitoUser(cognitoUser);
      delete userAttributes.email_verified;
      delete userAttributes.phone_number_verified;
      let sessionUserAttributes = userAttributes;
      const { setSessionUserAttributes } = userContext;
      setSessionUserAttributes(sessionUserAttributes);
      // setCurrentStep(MenuSteps.setPassword);
    },
  });
};
