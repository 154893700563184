import React from 'react';
import NavigationProvider from 'components/QuoteForm/providers/NavigationProvider';

import Menu from './components/Menu';

class LoginPage extends React.PureComponent {
  public render() {
    return (
      <NavigationProvider>
        <Menu />
      </NavigationProvider>
    );
  }
}
export default LoginPage;
