import React, { useContext } from 'react';
import { H2, BackLink, Link, Text } from 'components/Login';
import { FormNavigationContext } from 'components/QuoteForm/providers/NavigationProvider';
import { UserContext, LoadedProviderProps } from '/providers/UserProvider';

import { MenuSteps } from '..';

const CheckEmail = (props: { title: string; email: string }) => {
  const userContext = useContext(UserContext);
  let { hasCognitoRecord, forgotEmail } = userContext as LoadedProviderProps;
  let { title = 'Check your email' } = props;

  const { setCurrentStep } = useContext(FormNavigationContext);
  return (
    <React.Fragment>
      <H2>{title}</H2>
      <Text>
        {`If ${forgotEmail} is associated with an account, you will receive
        instructions on how to access your account.`}
      </Text>
      {!hasCognitoRecord && <Link href={'/'}>Return to homepage</Link>}
      {hasCognitoRecord && (
        <Link onClick={() => setCurrentStep(MenuSteps.forgotPassword)}>
          Reset Password
        </Link>
      )}
      <BackLink
        onClick={() => {
          setCurrentStep(0);
        }}
      />
    </React.Fragment>
  );
};

CheckEmail.displayName = 'check-email';

export default CheckEmail;
