import React from 'react';
import styled from 'styled-components';

const ErrorMessage = ({ errors, attr }: { errors: any; attr: string }) => {
  return (
    <ErrorMessageContainer>
      {errors && errors[attr] && <Message>{errors[attr]}</Message>}
    </ErrorMessageContainer>
  );
};

const ErrorMessageContainer = styled('div')`
  height: 40px;
  width: 100%;
`;

const Message = styled('div')`
  color: ${({ theme }) => theme.colors.bodyTextWarning};
  font-family: Brown;
  font-size: 16px;
  line-height: 18px;
  font-weight: 100;
`;

export default ErrorMessage;
