import styled from 'styled-components';

const Link = styled('a')`
  display: block;
  font-family: Brown;
  font-size: 18px;
  line-height: 24px;
  align-items: center;
  color: ${({ theme }) => theme.baseBlue};
  margin-bottom: 150px;
`;

export default Link;
