import React, { useContext, useState } from 'react';
import { UserContext, LoadedProviderProps } from '/providers/UserProvider';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { FormNavigationContext } from 'components/QuoteForm/providers/NavigationProvider';

import { MenuSteps } from '../';

import Password from './Password';
import { SimpleKV } from '/types';

const ForgotPassword = () => {
  const [passwordSet] = useState(false);
  const { setCurrentStep } = useContext(FormNavigationContext);

  const { userPool, forgotEmail, setConfirmationText } = useContext(
    UserContext
  ) as LoadedProviderProps;
  const handleResetPassword = async (
    values: SimpleKV,
    setErrors: (err: any) => void,
    _setStatus: any
  ) => {
    let cognitoUser = new CognitoUser({
      Username: forgotEmail,
      Pool: userPool,
    });
    await cognitoUser.confirmPassword(values['inputCode'], values['password'], {
      onFailure(err: Error) {
        //@ts-ignore  AWS Cognito TypeFiles haven't been updated
        if (err.code === 'CodeMismatchException') {
          setErrors(err);
        }
      },
      onSuccess() {
        setConfirmationText(
          'Your password has been reset. Please try logging in with the new password.'
        );
        setCurrentStep(MenuSteps.login);
      },
    });
  };
  return (
    <Password
      title={'Enter your password'}
      submitText={'Reset Password'}
      resetPassword={true}
      handleSubmit={handleResetPassword}
      passwordSet={passwordSet}
    />
  );
};

export default ForgotPassword;
