import { CognitoUser } from 'amazon-cognito-identity-js';
import gql from 'graphql-tag';
import initApollo from '/apollo-wrapper/init-apollo';
const redirectToLogin = () => {
  // TODO uncomment after password login is in prod
  // location.href = '/login';
  location.href = '/';
};

const mutation = gql`
  mutation Logout {
    logout {
      ok
    }
  }
`;

// Destroy any Cognito tokens and log out from django session
export const signOut = async (
  cognitoUser: CognitoUser
  // TODO uncomment after password login is in prod
  // redirect: boolean = true
): Promise<boolean> => {
  try {
    const client = initApollo();
    await client.mutate({
      mutation: mutation,
    });
    if (cognitoUser && cognitoUser.signOut) await cognitoUser.signOut();
    // TODO uncomment after password login is in prod
    redirectToLogin();
    // if (redirect) redirectToLogin();
    return true;
  } catch {
    return false;
  }
};
