import React from 'react';

const styles: React.CSSProperties = {
  color: '#fff',
  fontSize: '20px',
  lineHeight: '22px',
  fontFamily: 'Brown',
  background: 'rgba(9, 57, 105, 0.8)',
  borderRadius: '2px',
  textAlign: 'center',
  minWidth: '188px',
  height: '48px',
  marginBottom: '40px',
};

interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}

const Button = (props: ButtonProps) => {
  const { children = '', onClick = () => {}, disabled } = props;
  return (
    <button
      onClick={onClick}
      type={'button'}
      style={styles}
      disabled={!!disabled}
    >
      {children}
    </button>
  );
};

export default Button;
