import { validation } from '/util';

import React, { useContext } from 'react';
import { Form, Formik } from 'formik';
import { Button, H2, Input, BackLink, Text } from 'components/Login';
import { FormNavigationContext } from 'components/QuoteForm/providers/NavigationProvider';
import { UserContext, LoadedProviderProps } from '/providers/UserProvider';
import initApollo from '/apollo-wrapper/init-apollo';
import _ from 'lodash/fp';
import gql from 'graphql-tag';

import ErrorMessage from '../shared/ErrorMessage';
import { MenuSteps } from '../';

const validate = (values: { email: string }) => {
  let errors: {
    email: string[];
  } = { email: [] };

  if (values.email && !validation.isEmail(values.email)) {
    errors.email.push('A valid email is required.');
  }

  return errors;
};

const EnterEmail = (props: {
  title: string;
  submitText: string;
  handleSubmit: () => void;
}) => {
  const { title = 'Enter your email', submitText = 'Send email' } = props;

  const { setCurrentStep } = useContext(FormNavigationContext);
  const { setForgotEmail, setHasCognitoRecord } = useContext(
    UserContext
  ) as LoadedProviderProps;

  const handleResetLinkOrPassword = async (values: { email: string }) => {
    const client = initApollo();
    setForgotEmail(values.email);
    const mutation = gql`
      mutation ResetLinkPassword {
        forgotPassword(email: "${values.email}") {
          ok
          hasCognitoRecord
        }
      }
    `;
    const response = await client.mutate({ mutation });
    const hasCognitoRecord = _.getOr(false, [
      'data',
      'forgotPassword',
      'hasCognitoRecord',
    ])(response);
    setHasCognitoRecord(hasCognitoRecord);
    setCurrentStep(MenuSteps.checkMail);
  };
  return (
    <React.Fragment>
      <H2>{title}</H2>
      <Text>
        To access your account please provide the email associated with your
        case and we will send you an account access link
      </Text>
      <Formik
        initialValues={{ email: '' }}
        validate={validate}
        onSubmit={handleResetLinkOrPassword}
      >
        {({ isSubmitting, handleSubmit, errors }) => {
          return (
            <Form>
              <Input
                label={'Email address'}
                name={'email'}
                type={'text'}
                erred={!!errors.email}
                style={{ marginBottom: '5px' }}
              />
              <ErrorMessage errors={errors} attr={'message'} />
              <Button disabled={isSubmitting} onClick={handleSubmit}>
                {submitText}
              </Button>
              <BackLink
                onClick={() => {
                  setCurrentStep(MenuSteps.login);
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

EnterEmail.displayName = 'enterEmail';

export default EnterEmail;
