import React, { useContext } from 'react';
import styled from 'styled-components';
import * as Grid from 'components/Grid';
import { FormNavigationContext } from 'components/QuoteForm/providers/NavigationProvider';
import { UserContext, LoadedProviderProps } from '/providers/UserProvider';
import SimpleSpinner from 'components/SimpleSpinner';

import Login from './menus/Login';
import SetPassword from './menus/SetPassword';
import EnterEmail from './menus/EnterEmail';
import CheckEmail from './menus/CheckEmail';
import ManagePassword from './menus/ManagePassword';
import ForgotPassword from './menus/ForgotPassword';
import ConfirmRegistration from './menus/ConfirmRegistration';
import Verified from './menus/Verified';

export enum MenuSteps {
  login = 0,
  setPassword,
  managePassword,
  forgotPassword,
  enterEmail,
  checkMail,
  confirmRegistration,
  verified,
}

interface LoginMenuStep {
  (props): React.ReactElement;
}

const MenuList = (props: { steps: LoginMenuStep[] }) => {
  const { steps } = props;
  if (!steps) {
    return null;
  }
  const { currentStep /* setCurrentStep */ } = useContext(
    FormNavigationContext
  );
  const {
    // TODO uncomment after password login is in prod
    // userEmail,
    // cognitoUser,
    // authenticatedUser,
    isGettingUser,
  } = useContext(UserContext) as LoadedProviderProps;
  // TODO uncomment after password login is in prod
  // if (userEmail && !cognitoUser) {
  //   setCurrentStep(MenuSteps.setPassword);
  // } else if (authenticatedUser && authenticatedUser.signInUserSession) {
  //   setCurrentStep(MenuSteps.managePassword);
  // }
  // check userprovider is still checking a user is logged in or not
  if (isGettingUser) {
    return <CenteredSmallSpinner />;
  }
  const StepToRender = steps[currentStep];
  return (
    <GridContainer w={4}>
      <GridBox w={4}>
        <StepToRender />
      </GridBox>
    </GridContainer>
  );
};

const Menu = () => {
  return (
    <MenuList
      steps={[
        Login,
        SetPassword,
        ManagePassword,
        ForgotPassword,
        EnterEmail,
        CheckEmail,
        ConfirmRegistration,
        Verified,
      ]}
    />
  );
};

const GridContainer = styled(Grid.Grid)`
  justify-content: center;
`;

const GridBox = styled(Grid.GridBox)`
  margin-bottom: 100px;

  @media (max-width: ${Grid.columns.medium}px) {
    margin-bottom: 50px;
  }
`;
const CenteredSmallSpinner = styled(SimpleSpinner)`
  display: block;
  font-size: 8px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export default Menu;
