import React, { useContext, useState } from 'react';
import { UserContext, LoadedProviderProps } from '/providers/UserProvider';

import { redirectToPaperwork } from '../api/login';
import { SimpleKV } from '/types';

import Password from './Password';

//todo: handle password change for an authenticated user (requires mfa)

const ManagePassword = () => {
  const [passwordSet, setPasswordSet] = useState(false);
  const { cognitoUser } = useContext(UserContext) as LoadedProviderProps;
  const handleChangePassword = async (
    values: SimpleKV,
    _setErrors: any,
    setStatus: Function
  ) => {
    const { oldPassword, password } = values;
    await cognitoUser.changePassword(oldPassword, password, function(err) {
      if (err) {
        let error: { message: string } = { message: '' };
        if (err['code'] === 'LimitExceededException') {
          error['message'] = err.message;
          setStatus(error);
        } else {
          error['message'] = 'Incorrect old password';
          setStatus(error);
        }
        return;
      }
      setPasswordSet(true);
      redirectToPaperwork();
    });
  };
  return (
    <Password
      title={'Enter password'}
      submitText={'Reset Password'}
      handleSubmit={handleChangePassword}
      changePassword={true}
      passwordSet={passwordSet}
    />
  );
};

ManagePassword.displayName = 'managepassword';

export default ManagePassword;
