import styled from 'styled-components';

const Label = styled('label')`
  display: block;
  color: ${({ theme }) => theme.login.label};
  font-family: Brown;
  font-size: 16px;
  line-height: 18px;
  font-weight: 100;
  margin-bottom: 5px;
  margin-top: 5px;

  &:not(:first-of-type) {
    margin-top: 32px;
  }
`;

export default Label;
