import React from 'react';
import styled from 'styled-components';

const BackLink = (props: { onClick: () => void }) => {
  const { onClick = () => {} } = props;
  return (
    <StyleWrapper>
      <span onClick={onClick}>
        <Icon className={'tulicon-icon_arrow-forward'} />
        Back
      </span>
    </StyleWrapper>
  );
};

const StyleWrapper = styled('a')`
  font-family: Brown;
  font-size: 16px;
  line-height: 21px;
  color: #505f6d;
  display: block;
  font-weight: 100;

  :hover {
    cursor: pointer;
  }
`;

const Icon = styled('span')`
  transform: rotate(180deg);
  display: inline-block;
`;

export default BackLink;
