import React from 'react';
import { Field } from 'formik';

import { theme } from '../../../globalStyles';

interface StyleDictionary {
  [key: string]: React.CSSProperties;
}

const styles: StyleDictionary = {
  base: {
    width: '304px',
    display: 'block',
    boxSizing: 'border-box',
    borderRadius: 2,
    border: '1px solid ' + theme.login.borderColor,
    fontSize: '14px',
    padding: '12px 10px 14px 20px',
    fontWeight: 100,
    marginBottom: '32px',
  },
  baseErred: {
    background: 'rgba(187, 17, 34, 0.02)',
    borderColor: '#BB1122',
  },
  label: {
    display: 'block',
    color: theme.login.label,
    fontFamily: 'Brown',
    fontSize: '16px',
    lineHeight: '18px',
    fontWeight: 100,
    marginBottom: '5px',
    marginTop: '5px',
  },
  erred: {
    color: '#bb1122',
  },
};

//todo: refactor input later to use a base component

interface InputProps {
  name: string;
  label: string;
  type?: string;
  placeholder?: string;
  maxLength?: number;
  disabled?: boolean;
  style?: object;
  erred?: boolean;
  onChange?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  autoFocus?: boolean;
}

const Input = ({
  name = 'generic_input',
  onFocus = () => {},
  onBlur = () => {},
  label = '',
  type = 'text',
  erred = false,
  placeholder = '',
  maxLength,
  autoFocus,
  disabled,
  style = {},
}: InputProps) => {
  return (
    <React.Fragment>
      {label && (
        <label
          style={{
            ...styles.label,
            ...(erred ? styles.erred : {}),
          }}
        >
          {label}
        </label>
      )}
      <Field
        style={{
          ...styles.base,
          ...style,
          ...(erred ? styles.baseErred : {}),
        }}
        type={type}
        name={name}
        onFocus={onFocus}
        onBlur={onBlur}
        autoFocus={autoFocus}
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
      />
    </React.Fragment>
  );
};

export default Input;
