import { validation } from '/util';

import React, { useContext } from 'react';
import { Form, Formik } from 'formik';
import { UserContext, LoadedProviderProps } from '/providers/UserProvider';
import { FormNavigationContext } from 'components/QuoteForm/providers/NavigationProvider';
import { Button, H2, Input, Link } from 'components/Login';

import ErrorMessage from '../shared/ErrorMessage';
import { login } from '../api/login';
import { MenuSteps } from '../';

// Formik functions
// region Formik

const validate = values => {
  let errors: {
    email?: string;
    password?: string;
    message?: string;
  } = {};
  if (values.email && !validation.isEmail(values.email)) {
    errors.email = 'A valid email is required.';
  }
  return errors;
};

// endregion

const Login = (props: { title: string }) => {
  const { title = 'Log In' } = props;
  const { setCurrentStep } = useContext(FormNavigationContext);
  const userContext = useContext(UserContext) as LoadedProviderProps;
  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    await login(values, setCurrentStep, userContext, setErrors);
    userContext.setConfirmationText('');
    setSubmitting(false);
  };

  return (
    <React.Fragment>
      <H2>{title}</H2>
      <Formik
        initialValues={{ email: '', password: '' }}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, handleSubmit, errors }) => {
          return (
            <Form name={'login-form'}>
              <Input
                label={'Email address'}
                name={'email'}
                erred={!!errors.email}
              />
              <Input
                label={'Password'}
                name={'password'}
                type={'password'}
                erred={!!errors.password}
                style={{ marginBottom: '5px' }}
              />
              <ErrorMessage errors={errors} attr={'message'} />
              <Button disabled={isSubmitting} onClick={handleSubmit}>
                Log in
              </Button>
            </Form>
          );
        }}
      </Formik>
      <p>{userContext.confirmationText}</p>
      <Link
        onClick={() => {
          setCurrentStep(MenuSteps.enterEmail);
        }}
      >
        Create or reset password/login link
      </Link>
    </React.Fragment>
  );
};

Login.displayName = 'login';

export default Login;
