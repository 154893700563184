import styled from 'styled-components';

const Text = styled('p')`
  font-family: Brown;
  font-size: 18px;
  line-height: 28px;
  font-weight: 100;
  color: ${({ theme }) => theme.login.text};

  &:first-child {
    margin-top: 32px;
  }

  margin-bottom: 40px;
`;

export default Text;
