import { SentryManager } from '/util';

import { signOut } from 'pages/Login/components/Menu/api/logout';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import React, { useContext, useState } from 'react';
import { H2, Text, BackLink } from 'components/Login';
import { FormNavigationContext } from 'components/QuoteForm/providers/NavigationProvider';
import { UserContext, LoadedProviderProps } from '/providers/UserProvider';

import { getSessionUser } from '../../../api/getSessionUser';

import { SimpleKV } from '/types';
import Password from './Password';

import { MenuSteps } from '..';

const SetPassword = () => {
  const {
    cognitoUser,
    sessionUserAttributes,
    setCognitoUser,
    setUserEmail,
    setPassword,
    userPool,
  } = useContext(UserContext) as LoadedProviderProps;
  const [passwordSet, setPasswordSet] = useState(false);
  const { setCurrentStep } = useContext(FormNavigationContext);

  const handleSetPassword = async (values: SimpleKV) => {
    const { password } = values;
    const customer = await getSessionUser();
    if (!customer) {
      throw new Error('Failed to get user when trying to set Password');
    }
    if (!cognitoUser) {
      const attributeList = [
        new CognitoUserAttribute({ Name: 'email', Value: customer.email }),
        new CognitoUserAttribute({
          Name: 'given_name',
          Value: customer.firstName,
        }),
        new CognitoUserAttribute({
          Name: 'family_name',
          Value: customer.lastName,
        }),
      ];
      await userPool.signUp(
        customer.email,
        password,
        attributeList,
        [],
        function(err, result) {
          if (err) {
            alert(err.message || JSON.stringify(err));
            return;
          }
          if (result) {
            let cognitoUser = result.user;
            setCognitoUser(cognitoUser);
            setUserEmail(customer.email);
            setPassword(password);
            setCurrentStep(MenuSteps.confirmRegistration);
          }
        }
      );
    } else {
      await cognitoUser.completeNewPasswordChallenge(
        password,
        sessionUserAttributes,
        {
          onSuccess: () => {
            setPasswordSet(true);
          },
          onFailure: e => {
            SentryManager.captureException(e);
          },
        }
      );
    }
    // Use version below when Password Login has been added to Prod
    //signOut(cognitoUser, false);
    signOut(cognitoUser);
  };

  return (
    <React.Fragment>
      <Password
        title={'Set your password'}
        submitText={'Set Password'}
        handleSubmit={handleSetPassword}
        passwordSet={passwordSet}
      />
      {passwordSet && (
        <React.Fragment>
          <H2>Password Set</H2>
          <Text>Your password has been set. Please use it to log in.</Text>
          <BackLink
            onClick={() => {
              setCurrentStep(0);
            }}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

SetPassword.displayName = 'set-password';

export default SetPassword;
