import { Form, Formik } from 'formik';
import React, { useContext } from 'react';
import { Button, H2, Input, Text } from 'components/Login';
import { FormNavigationContext } from 'components/QuoteForm/providers/NavigationProvider';
import { UserContext, LoadedProviderProps } from '/providers/UserProvider';
import { AuthenticationDetails } from 'amazon-cognito-identity-js';

import { MenuSteps } from '../index';
import ErrorMessage from '../shared/ErrorMessage';

const ConfirmRegistration = () => {
  const { setCurrentStep } = useContext(FormNavigationContext);
  const {
    cognitoUser,
    password,
    userEmail,
    setPassword,
    setConfirmationText,
  } = useContext(UserContext) as LoadedProviderProps;
  const verifyEmail = async (
    values: { code: string },
    { setErrors }: { setErrors: any }
  ) => {
    const authenticationData = {
      Username: userEmail,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    cognitoUser.confirmRegistration(values.code, true, (err: any) => {
      if (err) {
        const error = {
          message: 'Invalid verification code provided, please try again.',
        };
        setErrors(error);
        return;
      }
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: () => {
          setPassword(null);
          setCurrentStep(MenuSteps.verified);
        },
        onFailure: () => {
          setCurrentStep(MenuSteps.login);
          setConfirmationText(
            'Your account has been verified. Please try logging in again with your password'
          );
        },
      });
    });
    return null;
  };
  return (
    <React.Fragment>
      <H2>Enter your code</H2>
      <Text>
        To verify your account please provide the code that was sent to your
        email.
      </Text>
      <Formik initialValues={{ code: '' }} onSubmit={verifyEmail}>
        {({ isSubmitting, handleSubmit, errors }) => {
          return (
            <Form>
              <Input
                label={'Verification Code'}
                name={'code'}
                type={'text'}
                style={{ marginBottom: '5px' }}
              />
              <ErrorMessage errors={errors} attr={'message'} />
              <Button disabled={isSubmitting} onClick={handleSubmit}>
                Verify Account
              </Button>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default ConfirmRegistration;
