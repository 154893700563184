import React from 'react';
import { Form, Formik } from 'formik';
import { Button, H2, Input } from 'components/Login';
import _ from 'lodash/fp';
import { SimpleKV } from '/types';

import ErrorMessage from '../shared/ErrorMessage';

const validate = values => {
  let errors: { password?: string } = {};
  if (values.password != values.reenter) {
    errors.password = 'Passwords must match';
  }
  if (values.password.length < 5) {
    errors.password = 'Passwords must be more than 5 characters';
  }
  return errors;
};

const Password = (props: {
  title: string;
  submitText: string;
  handleSubmit: (
    values: SimpleKV,
    setErrors: (err: any) => void,
    setStatus: Function
  ) => Promise<void>;
  passwordSet?: boolean;
  resetPassword?: boolean;
  changePassword?: boolean;
}) => {
  const {
    title,
    submitText,
    handleSubmit,
    passwordSet,
    changePassword,
    resetPassword,
  } = props;
  const passwordLabel =
    resetPassword || changePassword ? 'New Password' : 'Password';
  const confirmPasswordLabel = resetPassword
    ? 'Re-enter new password'
    : 'Re-enter password';
  return (
    <React.Fragment>
      {!passwordSet && (
        <React.Fragment>
          <H2>{title}</H2>
          <Formik
            initialValues={{ password: '', reenter: '' }}
            onSubmit={async (
              values,
              { setSubmitting, setErrors, setStatus }
            ) => {
              setSubmitting(true);
              const inputErrors = validate(values);
              await setErrors(inputErrors);
              if (_.isEmpty(inputErrors)) {
                await handleSubmit(values, setErrors, setStatus);
              }
              setSubmitting(false);
            }}
          >
            {({ isSubmitting, handleSubmit, errors, status }) => {
              return (
                <React.Fragment>
                  <Form>
                    {changePassword && (
                      <Input
                        label={'Old Password'}
                        name={'oldPassword'}
                        type={'password'}
                        erred={!!errors.password}
                      />
                    )}
                    {resetPassword && (
                      <Input
                        label={'Input Verification code'}
                        name={'inputCode'}
                        type={'text'}
                      />
                    )}
                    <Input
                      label={passwordLabel}
                      name={'password'}
                      type={'password'}
                      erred={!!errors.password}
                    />
                    <Input
                      label={confirmPasswordLabel}
                      name={'reenter'}
                      type={'password'}
                      erred={!!errors.password}
                    />
                    <ErrorMessage errors={errors} attr={'message'} />
                    <ErrorMessage errors={errors} attr={'password'} />
                    <ErrorMessage errors={status} attr={'message'} />
                    <Button disabled={isSubmitting} onClick={handleSubmit}>
                      {submitText}
                    </Button>
                  </Form>
                </React.Fragment>
              );
            }}
          </Formik>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Password;
